"use client";

import LoginComponent from "@/components/Login";
import { Authenticator } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import "./login.css";

export default function Login() {
  return (
    <div className="h-full w-full bg-white md:bg-secondary-foreground login-container py-4 md:py-0 md:px-12 flex md:items-center md:justify-center">
      <Authenticator.Provider>
        <LoginComponent />
      </Authenticator.Provider>
    </div>
  );
}
