"use client";

import {
  Authenticator,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { Loader2 } from "lucide-react";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
import Image from "next/image";

const formFields = {
  signUp: {
    name: {
      placeholder: "Enter your Name",
      isRequired: true,
      label: "Name",
      order: 1,
    },
    email: {
      placeholder: "Enter your Email",
      isRequired: true,
      label: "Email",
      order: 2,
    },
  },
};

const components = {
  Header: () => {
    return (
      <div className="mb-8 text-center px-2">
        <h1 className="text-xl md:text-2xl font-bold mb-2">
          Customer Login
        </h1>
        <p className="text-sm">
          If you&apos;ve purchased insurance through one of our partner
          platforms, you should create an account using the email address
          associated with the coverage.
        </p>
      </div>
    );
  },
  SignUp: {
    FormFields() {
      return (
        <>
          {/* Re-use default `Authenticator.SignUp.FormFields` */}
          <div className="bg-amber-50 p-4 text-sm rounded-md border border-amber-200">
            <strong>Password requirements</strong>
            <ul>
              <li>Minimum 8 characters</li>
              <li>Must contain at least 1 number</li>
              <li>Must contain at least 1 special character</li>
              <li>Must contain at least 1 uppercase letter</li>
              <li>Must contain at least 1 lowercase letter</li>
            </ul>
          </div>
          <Authenticator.SignUp.FormFields />
        </>
      );
    }
  }
};

export default function LoginComponent() {
  const { user, isPending, authStatus } = useAuthenticator((context) => [context.user, context.isPending, context.authStatus]);
  const router = useRouter();

  useEffect(() => {
    if (user) {
      router.replace("/");
    }
  }, [user]);

  if (isPending || authStatus === 'configuring' || authStatus === 'authenticated') {
    return (
      <div className="h-full w-full flex place-content-center items-center">
        <Loader2 className="animate-spin w-10 h-10" />
      </div>
    )
  }

  return (
    <div className="flex flex-col items-center m-auto md:m-0 gap-4">
      <Image
        alt="Vertical Insure"
        width={190}
        height={65}
        className="w-48"
        unoptimized
        src="https://brand.verticalinsure.com/assets/vi-coverage-logo_by-vi.png"
      />
      <div className="bg-white rounded-xl md:pt-4 md:border md:border-gray-300">
        <Authenticator
          loginMechanisms={["email"]}
          signUpAttributes={["name"]}
          components={components}
          formFields={formFields}
          socialProviders={["google"]}
          className="flex-1 md:h-full" />
      </div>
    </div>
  )
}
